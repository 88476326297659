// import firebase from 'firebase'; // DevPackage
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import store from '../store';

const config = {
  apiKey: 'AIzaSyCqKWnkFFgvAMDaU5j-Vqh7hfAMaVZhvIY',
  authDomain: 'future-laureates-academy.firebaseapp.com',
  databaseURL: 'https://future-laureates-academy.firebaseio.com',
  projectId: 'future-laureates-academy',
  storageBucket: 'future-laureates-academy.appspot.com',
  messagingSenderId: '115786929108',
};

const fire = firebase.initializeApp(config);
// Sign In User
fire.signIn = async (Email, Pass) => {
  try {
    await firebase.auth().signInWithEmailAndPassword(Email, Pass);
    store.commit('setCurrentUser', firebase.auth().currentUser);
    return true;
  } catch (err) {
    return err;
  }
};
// Sign Out User
fire.signOut = async () => {
  try {
    await firebase.auth().signOut();
    await store.commit('setCurrentUser', null);
    await store.commit('setStudentID', null);
    await store.commit('setStudentInfo', null);
    await store.commit('setNotification', null);
    await store.commit('setTimetable', null);
    await store.commit('setAttendance', null);
    await store.commit('setReceipt', null);
    await store.commit('setResult', null);
    return true;
  } catch (err) {
    return err;
  }
};

export default fire;
