import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vuetify, {
  iconfont: 'md' || 'mdi',
  theme: {
    primary: '#5568F2',
    radio: '#243DF2',
    secondary: '#434447',
    background: '#f8f8f8',
  },
});
