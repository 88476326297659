import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import './plugins/vuetify';
import axios from 'axios';
import App from './App.vue';
import AppLoading from './AppLoading.vue';
import router from './router';
import fire from './configuration/fire';
import store from './store';
import './registerServiceWorker';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';

axios.interceptors.request.use(
  async (config) => {
    config.headers.FireAuthToken = await fire.auth().currentUser.getIdToken(true);
    return config;
  },
  error => Promise.reject(error),
);

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: 'UA-110529539-3',
  router,
});

function start(val) {
  if (!val) {
    new Vue({
      router,
      render: h => h(AppLoading),
    }).$mount('#loading');
  } else {
    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app');
  }
}

start();

fire.auth().onAuthStateChanged((user) => {
  if (user) {
    store.commit('setCurrentUser', user);
  } else {
    store.commit('setCurrentUser', null);
  }
  start(true);
});
