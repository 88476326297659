import Vue from 'vue';
import Router from 'vue-router';
import routerAuth from './configuration/routerAuth';

Vue.use(Router);
const CPNT = name => () => import(`./views/${name}`);

const folderRoute = [
  {
    path: '/receipt',
    folder: 'Receipt',
    wildcard: 'view',
  },
  {
    path: '/Result',
    folder: 'Result',
    wildcard: 'view',
  },
  {
    path: '/notification',
    folder: 'Notification',
    wildcard: 'view',
  },
];
const Routes = [];
// eslint-disable-next-line
folderRoute.forEach(({ path, folder, ...args }) => {
  Routes.push({
    path,
    name: folder,
    component: CPNT(`${folder}/index`),
    beforeEnter: routerAuth,
  });
  if (args.wildcard) {
    Routes.push({
      path: `${path}/:id`,
      name: `${folder + args.wildcard}`,
      component: CPNT(`${folder}/${args.wildcard}`),
      beforeEnter: routerAuth,
    });
  }
});

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: CPNT('Dashboard'),
      beforeEnter: routerAuth,
    },
    {
      path: '/contact',
      name: 'Contact',
      component: CPNT('Contact'),
      beforeEnter: routerAuth,
    },
    {
      path: '/timetable',
      name: 'TimeTable',
      component: CPNT('TimeTable'),
      beforeEnter: routerAuth,
    },
    {
      path: '/attendance',
      name: 'Attendance',
      component: CPNT('Attendance'),
      beforeEnter: routerAuth,
    },
    {
      path: '/login',
      name: 'Login',
      component: CPNT('Login'),
      // beforeEnter: routerAuth,
    },
    {
      path: '/logout',
      name: 'Logout',
      component: CPNT('Logout'),
      beforeEnter: routerAuth,
    },

    // Folder Routes
    ...Routes,
    // 404 Redirect's to Dashboard
    {
      path: '*',
      redirect: '/dashboard',
    },
  ],
});
