import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: null,
    studentID: null,
    studentInfo: null,
    notification: null,
    timetable: null,
    attendance: null,
    receipt: null,
    result: null,
  },
  mutations: {
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    setStudentID(state, val) {
      state.studentID = val;
    },
    setStudentInfo(state, val) {
      state.studentInfo = val;
    },
    setNotification(state, val) {
      state.notification = val;
    },
    setTimetable(state, val) {
      state.timetable = val;
    },
    setAttendance(state, val) {
      state.attendance = val;
    },
    setReceipt(state, val) {
      state.receipt = val;
    },
    setResult(state, val) {
      state.result = val;
    },
  },
  actions: {},
  plugins: [createPersistedState({ key: 'futurelaureates' })],
});
