<template>
  <VApp>
    <VNavigationDrawer
      v-if="currentUser"
      v-model="drawer"
      persistent
      enable-resize-watcher
      fixed
      width="200"
      app
    >
      <div id="dash-logo">
        <img
          :src="require('./assets/images/logo.png')"
          alt="LOGO"
        >
      </div>
      <VList>
        <VFadeTransition
          mode="out-in"
          group
        >
          <template v-if="currentUser">
            <VListTile
              v-for="(item, i) in loginItems"
              :key="i"
              :to="item.to"
            >
              <VListTileAction>
                <VIcon>{{ item.icon }}</VIcon>
              </VListTileAction>
              <VListTileContent>
                <VListTileTitle v-text="item.title" />
              </VListTileContent>
            </VListTile>
          </template>
        </VFadeTransition>
      </VList>
    </VNavigationDrawer>
    <VToolbar
      v-if="currentUser"
      flat
      app
      color="background"
      dark
      dense
    >
      <VBtn
        icon
        flat
      >
        <VIcon
          color="primary"
          @click.stop="drawer = !drawer"
        >
          menu
        </VIcon>
      </VBtn>
    </VToolbar>
    <VContent>
      <VFadeTransition mode="out-in">
        <RouterView />
      </VFadeTransition>
    </VContent>
    <VDialog
      v-model="installApp"
      no-click-animation
      max-width="350"
      persistent
    >
      <VCard
        id="installcard"
        class="elevation-24"
      >
        <div class="closeInstall">
          <VBtn
            icon
            small
            class="ma-0"
            @click="goAway()"
          >
            <VIcon color="white">
              close
            </VIcon>
          </VBtn>
        </div>
        <img src="./assets/images/icon/applogo.svg">
        <p class="textSubTitle textBold text-uppercase textSmall mt-0 mb-3 white--text">
          Get The App Now
        </p>
        <p class="textSubTitle textBold text-uppercase mt-0 mb-1 white--text">
          Why Use The App?
        </p>
        <p class="textSubTitle textBold text-uppercase textSmall mt-0 white--text">
          <VIcon
            color="white"
            small
          >
            fiber_manual_record
          </VIcon> It's FAST
        </p>
        <p class="textSubTitle textBold text-uppercase textSmall mt-0 white--text">
          <VIcon
            color="white"
            small
          >
            fiber_manual_record
          </VIcon> It's Responsive
        </p>
        <p class="textSubTitle textBold text-uppercase textSmall mt-0 white--text">
          <VIcon
            color="white"
            small
          >
            fiber_manual_record
          </VIcon> It works Offline
        </p>
        <p class="textSubTitle textBold text-uppercase textSmall mt-4 white--text">
          So, What are you waiting for?
        </p>

        <div>
          <VBtn
            color="white"
            outline
            round
            block
            class="ma-0 mt-3 btnText"
            @click="installer()"
          >
            INSTALL NOW
          </VBtn>
        </div>
      </VCard>
    </VDialog>
    <VSnackbar
      v-model="notify"
      :timeout="notifyTimeout"
      class="textSubTitleNormal white--text"
    >
      {{ notifyTxt }}
    </VSnackbar>
  </VApp>
</template>

<script>
export default {
  data() {
    return {
      installApp: false,
      appOffline: false,

      notify: false,
      notifyTimeout: 3000,
      notifyTxt: '',

      drawer: null,
      isLogin: false,
      loginItems: [
        {
          icon: 'dashboard',
          title: 'Dashboard',
          to: '/',
        },
        {
          icon: 'notification_important',
          title: 'Notification',
          to: '/notification',
        },
        {
          icon: 'mdi-timetable',
          title: 'Time Table',
          to: '/timetable',
        },
        {
          icon: 'mdi-calendar-month',
          title: 'Attendance',
          to: '/attendance',
        },
        {
          icon: 'phone',
          title: 'Contact',
          to: '/contact',
        },
        {
          icon: 'receipt',
          title: 'Receipt',
          to: '/receipt',
        },
        {
          icon: 'school',
          title: 'Result',
          to: '/result',
        },
        {
          icon: 'exit_to_app',
          title: 'Logout',
          to: '/logout',
        },
      ],

    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  beforeMount() {
    if (this.$store.state.studentInfo === null) {
      this.$router.push('/logout');
    } else if (this.$store.state.studentInfo.admNo !== this.$store.state.studentID) {
      this.$router.push('/logout');
    }
  },
  mounted() {
    this.appOffline = window.appOffline;
    if (this.appOffline === true) {
      this.notify = true;
      this.notifyTxt = 'Looks like you\'re offline, some features may be unavailable.';
      this.notifyTimeout = 15000;
    } else {
      this.appOffline = false;
    }
  },

  beforeCreate() {
    let installPrompt;
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      installPrompt = e;
      this.installApp = true;
    });
    this.installer = () => {
      this.installApp = false;
      installPrompt.prompt();
      installPrompt.userChoice.then(() => {
        installPrompt = null;
      });
    };
  },
  methods: {
    goAway() {
      this.installApp = false;
      this.notify = true;
      this.notifyTxt = 'Installation Aborted';
      this.notifyTimeout = 15000;
    },
  },
};
</script>
<style lang="stylus">
@import './assets/stylus/text';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  margin: 0;
  padding: 0;
}

.application {
  background: #f8f8f8 !important;
}

#dash-logo {
  text-align: center;
  height: 200px;
  width: 100%;
  padding: 1rem;
}

#dash-logo img {
  width: 100px;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-navigation-drawer__border {
  width: 0;
}

#installcard {
  border-radius: 16px !important;
  padding: 1.5rem;
  background: #242424;
  color: #ffffff;
  position: relative;
}

#installcard img {
  min-height: 38px;
  width: 64px;
  border-radius: 0;
}

#installcard .closeInstall {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.btnText {
  font-family: 'Montserrat';
  font-weight: 600;
}
</style>
